import React from 'react';
import aboutUS from "../assets/images/webflixo office.png";

const page = () => { return (

    <section className="text-center mb-16">
      <div
        className="gap-8 items-center py-4 px-4 mx-auto max-w-screen-lg xl:gap-16 md:grid md:grid-cols-2 sm:py-8 lg:px-6"
      >
        <div className="text-left mt-4 md:mt-0 max-w-xs mx-auto mb-4 md:mb-0">
          <h2
            className="mb-4 text-2xl md:text-4xl tracking-tight font-extrabold text-gray-900"
          >Discover the Heart Behind WebFlixo
          </h2>
          <p
            className="mb-1 font-light text-gray-500 md:text-lg"
          >At WebFlixo, we are more than just a service provider - we are your dedicated partner in success. Founded with a passion for scaling your Agency, we strive to make a meaningful impact on your journey toward growth and prosperity.</p>
        </div>
        <img className="h-auto max-w-xs mx-auto rounded-lg" src={aboutUS} alt="about us" />
        
      </div>
      <div className="mx-auto mt-10 max-w-xs md:max-w-screen-lg px-6">
        <p className="text-left pb-8 md:pb-10 text-xl">Our Values Drive Us:</p>
        <dl className="text-left grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
          <div className="relative pl-16">
              <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div
                      className="absolute left-0 top-0 flex h-6 w-6 items-center justify-center rounded-sm bg-purple-400">
                      
                  </div>
                  Commitment to Excellence
              </dt>
              <dd className="mt-2 text-base leading-7 text-gray-600"> We are dedicated to delivering exceptional results and exceeding expectations at every turn.</dd>
          </div>
          <div className="relative pl-16">
              <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div
                      className="absolute left-0 top-0 flex h-6 w-6 items-center justify-center rounded-sm bg-purple-400">
                      
                  </div>
                  Innovation
              </dt>
              <dd className="mt-2 text-base leading-7 text-gray-600">Always follow creativity and forward-thinking, we constantly evolve to stay ahead of industry trends and advancements.
              </dd>
          </div>
          <div className="relative pl-16">
              <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div
                      className="absolute left-0 top-0 flex h-6 w-6 items-center justify-center rounded-sm bg-purple-400">
                      
                  </div>
                  Client-Centric Approach
              </dt>
              <dd className="mt-2 text-base leading-7 text-gray-600">Your success is our priority. We listen, collaborate, and tailor our solutions to align with your goals and aspirations.
              </dd>
          </div>
        </dl>
      </div>
      <div className="mx-auto mt-10 max-w-xs md:max-w-screen-lg px-6">
        <p className="text-left pb-8 md:pb-10 text-xl">What Sets Us Apart:</p>
        <dl className="text-left grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
          <div className="relative pl-16">
              <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div
                      className="absolute left-0 top-0 flex h-6 w-6 items-center justify-center rounded-sm bg-purple-400">
                      
                  </div>
                  Expertise
              </dt>
              <dd className="mt-2 text-base leading-7 text-gray-600"> Backed by years of experience and a team of seasoned professionals, we bring unparalleled expertise to every project.</dd>
          </div>
          <div className="relative pl-16">
              <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div
                      className="absolute left-0 top-0 flex h-6 w-6 items-center justify-center rounded-sm bg-purple-400">
                      
                  </div>
                  Personalized Solutions
              </dt>
              <dd className="mt-2 text-base leading-7 text-gray-600">We understand that no two businesses are alike. Thats why we offer customized strategies that cater to your specific needs and objectives.
              </dd>
          </div>
          <div className="relative pl-16">
              <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div
                      className="absolute left-0 top-0 flex h-6 w-6 items-center justify-center rounded-sm bg-purple-400">
                      
                  </div>
                  Reliability
              </dt>
              <dd className="mt-2 text-base leading-7 text-gray-600">Count on us to be your steadfast partner throughout your e-commerce journey. We are here to support you every step of the way.

              </dd>
          </div>
        </dl>
      </div>
    </section>
    
    ); }; export default page;