import React from "react";

const VideoTestimonial = () => {
  return (
    <div className="flex justify-center align-middle p-0 md:p-8">
      <div className="w-full md:w-2/3 lg:w-1/2 mx-auto">
        <iframe
          className="w-full h-48 md:h-72 rounded-xl"
          src="https://www.youtube.com/embed/V2gmvzdFkbU?si=knfVRuPyh0CkSdkU"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default VideoTestimonial;
