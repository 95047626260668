import React from 'react';
import Review from '../components/Review';

const ReviewPage = () => {
    return (
        <div className='py-12'>
            <Review/>
        </div>
    );
};

export default ReviewPage;