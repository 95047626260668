import React from 'react';
import meet from "../assets/images/how we work/1.png";
import trello from "../assets/images/how we work/2.png";
import discord from "../assets/images/how we work/3.png";
import analytics from "../assets/images/how we work/4.png";
const HowWeWorks = () => { return (
    <section>
      <div className="py-8 px-4 mx-auto max-w-screen-lg lg:py-16 lg:px-6">
        <div className="mx-auto text-center max-w-screen-sm">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">
          Make Your Workday Easier - Here's How
          </h2>
          <p className="mb-4 text-gray-500 sm:text-xl">
          Simple Steps to Take Control
          </p>
        </div>
        {/* Step-1 */}
        <div className="flex justify-center">
        <span className="text-xs md:text-base py-2 px-10 bg-gray-200 border border-gray-300 rounded-3xl drop-shadow-lg hover:scale-105 transition duration-700 ease-in-out"><span className="border border-gray-400 px-4 py-1 mr-4 rounded-3xl italic">Step-1</span>Kick-Off Call</span>
        </div>
        <div
          className="gap-8 items-center py-4 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-6 lg:px-6"
        >
          <img className="h-auto max-w-full rounded-lg" src={meet} alt="Microsoft Logo" />
          <div className="mt-4 md:mt-0 text-left">
            <h2
              className="mb-4 text-2xl tracking-tight font-extrabold text-gray-900"
            >
              Get A Clear Understanding Of Every Project With A Kick-Off Call.
            </h2>
            <div className="mb-6 font-light text-gray-500 md:text-lg text-left">
              <p>
                Every project starts with an efficient kick-off call. This is where we will learn everything necessary about what your client needs and set up strict deadlines as to when each phase will be done.
              </p>
            </div>
            <div>
          {/* <ButtonPair/> */}
            </div>
          </div>
        </div>
        {/* Step-2 */}
        <div className="flex justify-center">
        <span className="text-xs md:text-base py-2 px-10 bg-gray-200 border border-gray-300 rounded-3xl drop-shadow-lg hover:scale-105 transition duration-700 ease-in-out"><span className="border border-gray-400 px-4 py-1 mr-4 rounded-3xl italic">Step-2</span>Keep an Eye</span>
        </div>
        <div
          className="gap-8 items-center py-4 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-6 lg:px-6"
        >
          <img className="h-auto max-w-full rounded-lg" src={trello} alt="Microsoft Logo" />
          <div className="mt-4 md:mt-0 text-left">
            <h2
              className="mb-4 text-2xl tracking-tight font-extrabold text-gray-900"
            >
              Full Visibility into your teams fulfilment at all times
            </h2>
            <div className="mb-6 font-light text-gray-500 md:text-lg">
              {/* Painpoint */}
              <p>
              You get full visibility at all times. We plan out every project in Trello, giving you a complete understanding of what we're doing, how we're doing it, and when it'll be done. Your current white-label marketing agency should be sweating.
              </p>
            </div>
            <div>
          {/* <ButtonPair/> */}
            </div>
          </div>
        </div>
        {/* Step-3 */}
        <div className="flex justify-center">
        <span className="text-xs md:text-base py-2 px-10 bg-gray-200 border border-gray-300 rounded-3xl drop-shadow-lg hover:scale-105 transition duration-700 ease-in-out"><span className="border border-gray-400 px-4 py-1 mr-4 rounded-3xl italic">Step-3</span>Track</span>
        </div>
        <div
          className="gap-8 items-center py-4 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-6 lg:px-6"
        >
          <img className="h-auto max-w-full rounded-lg" src={discord} alt="Microsoft Logo" />
          <div className="mt-4 md:mt-0 text-left">
            <h2
              className="mb-4 text-2xl tracking-tight font-extrabold text-gray-900"
            >
              Never lose track of your clients projects
            </h2>
            <div className="mb-6 font-light text-gray-500 md:text-lg">
              {/* Painpoint */}
              <p>
              We set up a Discord Server to Connect with all of our white-label partners. This means you have a direct line of communication with your team at all times.
              </p>
            </div>
            <div>
          {/* <ButtonPair/> */}
            </div>
          </div>
        </div>
        {/* Step-4 */}
        <div className="flex justify-center">
        <span className="text-xs md:text-base py-2 px-10 bg-gray-200 border border-gray-300 rounded-3xl drop-shadow-lg hover:scale-105 transition duration-700 ease-in-out"><span className="border border-gray-400 px-4 py-1 mr-4 rounded-3xl italic">Step-4</span>Quantify</span>
        </div>
        <div
          className="gap-8 items-center py-4 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-6 lg:px-6"
        >
          <img className="h-auto max-w-full rounded-lg" src={analytics} alt="Microsoft Logo" />
          <div className="mt-4 md:mt-0 text-left">
            <h2
              className="mb-4 text-2xl tracking-tight font-extrabold text-gray-900"
            >
              Show your clients results
            </h2>
            <div className="mb-6 font-light text-gray-500 md:text-lg">
              {/* Painpoint */}
              <p>
              Showcase Client Impact: Leverage white-label reports to drive client growth with data-backed decisions.
              </p>
            </div>
            <div>
          {/* <ButtonPair/> */}
            </div>
          </div>
        </div>
      </div>
    </section>
    ); }; export default HowWeWorks;