import React from 'react';
import Services from '../components/Services';

const ServicePage = () => {
    return (
            <div className='my-12 '>
            <Services/>
            </div>
    );
};

export default ServicePage;