import React from 'react';
import ReviewGallery from './ReviewGallery';
import VideoTestimonial from './VideoTestimonial';

const Review = () => {
    return (
        <div className="container mx-auto p-8">
            <div className="mx-auto text-center max-w-screen-sm">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">
          Awesome Results from Our Clients
          </h2>
          <p className="mb-4 text-gray-500 sm:text-xl">
          Sloving their results with WebFlixo.
          </p>
        </div>
        <div className="p-4 lg:p-16 border border-1 border-gray-200 divide-y divide-gray-200">
            <VideoTestimonial/>
            <ReviewGallery/>
        </div>
    </div>
    );
};

export default Review;