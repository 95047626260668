import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import AboutUsPage from './pages/AboutUsPage';
import BookConsultation from './pages/BookConsultation';
import Home from './pages/Home';
import ReviewPage from './pages/ReviewPage';
import ServicePage from './pages/ServicePage';
import TeamPage from './pages/TeamPage';

function App() {
  return (
    <div className="App">

      <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/home" element={<Home/>} />
        <Route path="/consultation" element={<BookConsultation/>} />
        <Route path="/Services" element={<ServicePage/>} />
        <Route path="/review" element={<ReviewPage/>} />
        <Route path="/team" element={<TeamPage/>} />
        <Route path="/about" element={<AboutUsPage/>} />
        {/* <Route path="books/add" element={} />
        <Route path="books/edit/:id" element={} /> */}
      </Routes>
      <Footer/>
    </Router>
    </div>
  );
}

export default App;
