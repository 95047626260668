import React from 'react';
import { Link } from 'react-router-dom';
import photoOfSk from '../assets/images/Team Member/sk.jpg';
import photoOfTanvir from '../assets/images/Team Member/tanvirroni.png';
import photoOfJobayer from '../assets/images/Team Member/team-member-jobayer.png';
import photoOfNoman from '../assets/images/Team Member/team-member-noman.png';

const Team = () => {
    return (
        <section className="container mx-auto p-8">
        <div className="mx-auto max-w-screen-lg">
            <div className="mx-auto max-w-screen-sm text-center mb-8 lg:mb-16">
                <h6 className="text-6xl font-medium py-6">
                    Our Team
                </h6>
                <p className="font-light text-gray-500 lg:mb-16 sm:text-xl">Our diverse and passionate
                    team of experts collaborates to deliver exceptional digital solutions, driven by creativity and
                    innovation.</p>
            </div>
            <div className="grid gap-8 mb-6 lg:mb-16 md:grid-cols-2">
                <div className="pt-6 md:pt-0 items-center bg-gray-50 rounded-lg shadow shadow-lg sm:flex">
                    <Link to="#">
                        <div className="flex items-center justify-center">
                            <img className="w-1/2 lg:w-11/12 rounded-lg sm:rounded-none sm:rounded-l-lg"
                                src={photoOfJobayer} alt='Jobayer'/>
                        </div>
                    </Link>
                    <div className="p-5 text-center">
                        <h3 className="text-2xl font-light tracking-tight text-gray-900">
                            <p>Noor E Alom Jobyer</p>
                        </h3>
                        <span className="text-gray-500">CEO & Web Developer</span>
                        <p className="mt-3 mb-4 font-light text-gray-500">Transforming innovative ideas into dynamic,
                            interactive web realities
                            through the power of code.</p>
                        <div className="sm:mx-auto sm:w-full sm:max-w-sm flex items-center">
                            <hr className="flex-grow border-t-1 border-gray-400"/>
                            <ul className="flex justify-center items-center px-4 space-x-4 sm:mt-0">
                                <li>
                                    <Link to="https://www.facebook.com/wjobayer"
                                        className="text-gray-500 hover:text-gray-900" target="_blank">
                                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                            <path fill-rule="evenodd"
                                                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                                                clip-rule="evenodd" />
                                        </svg>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://www.instagram.com/marketingmickey/"
                                        className="text-gray-500 hover:text-gray-900" target="_blank">
                                        <svg className="w-5 h-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                            height="1em" viewBox="0 0 448 512">
                                            <path
                                                d="M224,202.66A53.34,53.34,0,1,0,277.36,256,53.38,53.38,0,0,0,224,202.66Zm124.71-41a54,54,0,0,0-30.41-30.41c-21-8.29-71-6.43-94.3-6.43s-73.25-1.93-94.31,6.43a54,54,0,0,0-30.41,30.41c-8.28,21-6.43,71.05-6.43,94.33S91,329.26,99.32,350.33a54,54,0,0,0,30.41,30.41c21,8.29,71,6.43,94.31,6.43s73.24,1.93,94.3-6.43a54,54,0,0,0,30.41-30.41c8.35-21,6.43-71.05,6.43-94.33S357.1,182.74,348.75,161.67ZM224,338a82,82,0,1,1,82-82A81.9,81.9,0,0,1,224,338Zm85.38-148.3a19.14,19.14,0,1,1,19.13-19.14A19.1,19.1,0,0,1,309.42,189.74ZM400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM382.88,322c-1.29,25.63-7.14,48.34-25.85,67s-41.4,24.63-67,25.85c-26.41,1.49-105.59,1.49-132,0-25.63-1.29-48.26-7.15-67-25.85s-24.63-41.42-25.85-67c-1.49-26.42-1.49-105.61,0-132,1.29-25.63,7.07-48.34,25.85-67s41.47-24.56,67-25.78c26.41-1.49,105.59-1.49,132,0,25.63,1.29,48.33,7.15,67,25.85s24.63,41.42,25.85,67.05C384.37,216.44,384.37,295.56,382.88,322Z" />
                                        </svg>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://twitter.com/wjobayer" className="text-gray-500 hover:text-gray-900"
                                        target="_blank">
                                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                            <path
                                                d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                                        </svg>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://www.linkedin.com/in/wjobayer/"
                                        className="text-gray-500 hover:text-gray-900" target="_blank">
                                        <svg className="w-5 h-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                            height="1em" viewBox="0 0 448 512">
                                            <path
                                                d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
                                        </svg>
                                    </Link>
                                </li>
                            </ul>
                            <hr className="flex-grow border-t-1 border-gray-400"/>
                        </div>

                    </div>
                </div>
                <div className="pt-6 md:pt-0 items-center bg-gray-50 rounded-lg shadow shadow-lg sm:flex">
                    <Link to="#">
                        <div className="flex items-center justify-center">
                            <img className="w-1/2 lg:w-11/12 rounded-lg sm:rounded-none sm:rounded-l-lg"
                                src={photoOfNoman} alt='Noman'/>
                        </div>
                    </Link>
                    <div className="p-5 text-center">
                        <h3 className="text-2xl font-light tracking-tight text-gray-900">
                            <p>Nafiul Islam Noman</p>
                        </h3>
                        <span className="text-gray-500">Founder & SEO Specialist</span>
                        <p className="mt-3 mb-4 font-light text-gray-500">Boost your online presence with our human-touch
                            SEO Optimizer, driving higher search visibility.</p>
                        <div className="sm:mx-auto sm:w-full sm:max-w-sm flex items-center">
                            <hr className="flex-grow border-t-1 border-gray-400"/>
                            <ul className="flex justify-center items-center px-4 space-x-4 sm:mt-0">
                                <li>
                                    <Link to="https://www.facebook.com/nafiul.i.noman/"
                                        className="text-gray-500 hover:text-gray-900" target="_blank">
                                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                            <path fill-rule="evenodd"
                                                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                                                clip-rule="evenodd" />
                                        </svg>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://www.instagram.com/mohammadnafiulislam/"
                                        className="text-gray-500 hover:text-gray-900" target="_blank">
                                        <svg className="w-5 h-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                            height="1em" viewBox="0 0 448 512">
                                            <path
                                                d="M224,202.66A53.34,53.34,0,1,0,277.36,256,53.38,53.38,0,0,0,224,202.66Zm124.71-41a54,54,0,0,0-30.41-30.41c-21-8.29-71-6.43-94.3-6.43s-73.25-1.93-94.31,6.43a54,54,0,0,0-30.41,30.41c-8.28,21-6.43,71.05-6.43,94.33S91,329.26,99.32,350.33a54,54,0,0,0,30.41,30.41c21,8.29,71,6.43,94.31,6.43s73.24,1.93,94.3-6.43a54,54,0,0,0,30.41-30.41c8.35-21,6.43-71.05,6.43-94.33S357.1,182.74,348.75,161.67ZM224,338a82,82,0,1,1,82-82A81.9,81.9,0,0,1,224,338Zm85.38-148.3a19.14,19.14,0,1,1,19.13-19.14A19.1,19.1,0,0,1,309.42,189.74ZM400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM382.88,322c-1.29,25.63-7.14,48.34-25.85,67s-41.4,24.63-67,25.85c-26.41,1.49-105.59,1.49-132,0-25.63-1.29-48.26-7.15-67-25.85s-24.63-41.42-25.85-67c-1.49-26.42-1.49-105.61,0-132,1.29-25.63,7.07-48.34,25.85-67s41.47-24.56,67-25.78c26.41-1.49,105.59-1.49,132,0,25.63,1.29,48.33,7.15,67,25.85s24.63,41.42,25.85,67.05C384.37,216.44,384.37,295.56,382.88,322Z" />
                                        </svg>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://twitter.com/nafiul_webflixo"
                                        className="text-gray-500 hover:text-gray-900" target="_blank">
                                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                            <path
                                                d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                                        </svg>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://www.linkedin.com/in/webflixo-nafiulislam/"
                                        className="text-gray-500 hover:text-gray-900" target="_blank">
                                        <svg className="w-5 h-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                            height="1em" viewBox="0 0 448 512">
                                            <path
                                                d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
                                        </svg>
                                    </Link>
                                </li>
                            </ul>
                            <hr className="flex-grow border-t-1 border-gray-400"/>
                        </div>

                    </div>
                </div>
            </div>
            <div className="grid gap-8 mb-6 lg:mb-16 md:grid-cols-2">
                <div className="pt-6 md:pt-0 items-center bg-gray-50 rounded-lg shadow shadow-lg sm:flex">
                    <Link to="#">
                        <div className="flex items-center justify-center">
                            <img className="w-1/2 lg:w-11/12 rounded-lg sm:rounded-none sm:rounded-l-lg"
                                src={photoOfTanvir} alt='Tanvir'/>
                        </div>
                    </Link>
                    <div className="p-5 text-center">
                        <h3 className="text-2xl font-light tracking-tight text-gray-900">
                            <p>Tanvir Ahmed Roni</p>
                        </h3>
                        <span className="text-gray-500">Marketing Director</span>
                        <p className="mt-3 mb-4 font-light text-gray-500">Driving Strategic Vision and Brand Growth, and Market Innovation</p>
                        <div className="sm:mx-auto sm:w-full sm:max-w-sm flex items-center">
                            <hr className="flex-grow border-t-1 border-gray-400"/>
                            <ul className="flex justify-center items-center px-4 space-x-4 sm:mt-0">
                                <li>
                                    <Link to="https://www.facebook.com/ahamedtanvir99"
                                        className="text-gray-500 hover:text-gray-900" target="_blank">
                                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                            <path fill-rule="evenodd"
                                                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                                                clip-rule="evenodd" />
                                        </svg>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://www.instagram.com/ahamedtanvir99/"
                                        className="text-gray-500 hover:text-gray-900" target="_blank">
                                        <svg className="w-5 h-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                            height="1em" viewBox="0 0 448 512">
                                            <path
                                                d="M224,202.66A53.34,53.34,0,1,0,277.36,256,53.38,53.38,0,0,0,224,202.66Zm124.71-41a54,54,0,0,0-30.41-30.41c-21-8.29-71-6.43-94.3-6.43s-73.25-1.93-94.31,6.43a54,54,0,0,0-30.41,30.41c-8.28,21-6.43,71.05-6.43,94.33S91,329.26,99.32,350.33a54,54,0,0,0,30.41,30.41c21,8.29,71,6.43,94.31,6.43s73.24,1.93,94.3-6.43a54,54,0,0,0,30.41-30.41c8.35-21,6.43-71.05,6.43-94.33S357.1,182.74,348.75,161.67ZM224,338a82,82,0,1,1,82-82A81.9,81.9,0,0,1,224,338Zm85.38-148.3a19.14,19.14,0,1,1,19.13-19.14A19.1,19.1,0,0,1,309.42,189.74ZM400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM382.88,322c-1.29,25.63-7.14,48.34-25.85,67s-41.4,24.63-67,25.85c-26.41,1.49-105.59,1.49-132,0-25.63-1.29-48.26-7.15-67-25.85s-24.63-41.42-25.85-67c-1.49-26.42-1.49-105.61,0-132,1.29-25.63,7.07-48.34,25.85-67s41.47-24.56,67-25.78c26.41-1.49,105.59-1.49,132,0,25.63,1.29,48.33,7.15,67,25.85s24.63,41.42,25.85,67.05C384.37,216.44,384.37,295.56,382.88,322Z" />
                                        </svg>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://twitter.com/ahamedtanvir99" className="text-gray-500 hover:text-gray-900"
                                        target="_blank">
                                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                            <path
                                                d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                                        </svg>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://www.linkedin.com/in/ahamedtanvir99/"
                                        className="text-gray-500 hover:text-gray-900" target="_blank">
                                        <svg className="w-5 h-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                            height="1em" viewBox="0 0 448 512">
                                            <path
                                                d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
                                        </svg>
                                    </Link>
                                </li>
                            </ul>
                            <hr className="flex-grow border-t-1 border-gray-400"/>
                        </div>

                    </div>
                </div>
                <div className="pt-6 md:pt-0 items-center bg-gray-50 rounded-lg shadow shadow-lg sm:flex">
                    <Link to="#">
                        <div className="flex items-center justify-center">
                            <img className="w-1/2 lg:w-11/12 rounded-lg sm:rounded-none sm:rounded-l-lg"
                                src={photoOfSk} alt='Sk'/>
                        </div>
                    </Link>
                    <div className="p-5 text-center">
                        <h3 className="text-2xl font-light tracking-tight text-gray-900">
                            <p>SK. Yeasin Arafat</p>
                        </h3>
                        <span className="text-gray-500">Business Cosultant</span>
                        <p className="mt-3 mb-4 font-light text-gray-500">Shaping Strategic Solutions, Driving Business Growth, and Innovation</p>
                        <div className="sm:mx-auto sm:w-full sm:max-w-sm flex items-center">
                            <hr className="flex-grow border-t-1 border-gray-400"/>
                            <ul className="flex justify-center items-center px-4 space-x-4 sm:mt-0">
                                <li>
                                    <Link to="https://www.facebook.com/ay-yeasin-arafat"
                                        className="text-gray-500 hover:text-gray-900" target="_blank">
                                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                            <path fill-rule="evenodd"
                                                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                                                clip-rule="evenodd" />
                                        </svg>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://www.instagram.com/ay-yeasin-arafat/"
                                        className="text-gray-500 hover:text-gray-900" target="_blank">
                                        <svg className="w-5 h-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                            height="1em" viewBox="0 0 448 512">
                                            <path
                                                d="M224,202.66A53.34,53.34,0,1,0,277.36,256,53.38,53.38,0,0,0,224,202.66Zm124.71-41a54,54,0,0,0-30.41-30.41c-21-8.29-71-6.43-94.3-6.43s-73.25-1.93-94.31,6.43a54,54,0,0,0-30.41,30.41c-8.28,21-6.43,71.05-6.43,94.33S91,329.26,99.32,350.33a54,54,0,0,0,30.41,30.41c21,8.29,71,6.43,94.31,6.43s73.24,1.93,94.3-6.43a54,54,0,0,0,30.41-30.41c8.35-21,6.43-71.05,6.43-94.33S357.1,182.74,348.75,161.67ZM224,338a82,82,0,1,1,82-82A81.9,81.9,0,0,1,224,338Zm85.38-148.3a19.14,19.14,0,1,1,19.13-19.14A19.1,19.1,0,0,1,309.42,189.74ZM400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM382.88,322c-1.29,25.63-7.14,48.34-25.85,67s-41.4,24.63-67,25.85c-26.41,1.49-105.59,1.49-132,0-25.63-1.29-48.26-7.15-67-25.85s-24.63-41.42-25.85-67c-1.49-26.42-1.49-105.61,0-132,1.29-25.63,7.07-48.34,25.85-67s41.47-24.56,67-25.78c26.41-1.49,105.59-1.49,132,0,25.63,1.29,48.33,7.15,67,25.85s24.63,41.42,25.85,67.05C384.37,216.44,384.37,295.56,382.88,322Z" />
                                        </svg>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://twitter.com/ay-yeasin-arafat"
                                        className="text-gray-500 hover:text-gray-900" target="_blank">
                                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                            <path
                                                d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                                        </svg>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://www.linkedin.com/in/ay-yeasin-arafat/"
                                        className="text-gray-500 hover:text-gray-900" target="_blank">
                                        <svg className="w-5 h-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                            height="1em" viewBox="0 0 448 512">
                                            <path
                                                d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
                                        </svg>
                                    </Link>
                                </li>
                            </ul>
                            <hr className="flex-grow border-t-1 border-gray-400"/>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
    );
};

export default Team;