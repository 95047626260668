import React from "react";
import BuseKanal from "../assets/images/Clents Image/BUSE KANAL HM at Shyftup.jpg";
import dimitry from "../assets/images/Clents Image/Dimitry F.jpg";
import Michael from "../assets/images/Clents Image/Michael Schmidt at Audiencely.jpg";
import sara from "../assets/images/Clents Image/Sara Headshot.jpg";
import mdImranHossain from "../assets/images/Clents Image/md imran hossain Executive at Debuggers Studio.jpg";
import philDuggan from "../assets/images/Clents Image/phil duggan Director of Marketing at B7 M Agency.jpg";


const ReviewGallery = () => {
  return (
    <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
      <div class="grid gap-4">
          <figure class="flex flex-col items-start justify-start p-8 text-left bg-white border-b border-gray-200 rounded-t-lg md:rounded-t-none md:rounded-ss-lg md:border-e">
            <blockquote class="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8">
              <p class="my-4">
              I have to say, I was pleasantly surprised and honestly, blown away by the quality of work WebFlixo delivered.  I've worked with other agencies before, and let's just say my expectations weren't exactly soaring this time around.  But WebFlixo completely exceeded them!
              </p>
            </blockquote>
            <figcaption class="flex items-start justify-start">
              <img
                class="rounded-full w-9 h-9"
                src={dimitry}
                alt="logo"
              />
              <div class="space-y-0.5 font-medium rtl:text-right ms-3">
                <div>Dimitry F</div>
                <div class="text-sm text-gray-500">
                Co-Owner & CMO at XENA
                </div>
              </div>
            </figcaption>
          </figure> 
          <figure class="flex flex-col items-start justify-start p-8 text-left bg-white border-b border-gray-200 rounded-t-lg md:rounded-t-none md:rounded-ss-lg md:border-e">
            <blockquote class="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8">
              <p class="my-4">
              Great job. Everything was done quickly and efficiently. I will definitely continue cooperation. Thanks
              </p>
            </blockquote>
            <figcaption class="flex items-start justify-start">
              <img
                class="rounded-full w-9 h-9"
                src={sara}
                alt="logo"
              />
              <div class="space-y-0.5 font-medium text-left rtl:text-right ms-3">
                <div>Sahara Sara</div>
                <div class="text-sm text-gray-500">
                  Marketing Director at KanehBosm
                </div>
              </div>
            </figcaption>
          </figure>
      </div>
      <div class="grid gap-4">
          <figure class="flex flex-col items-start justify-start p-8 text-left bg-white border-b border-gray-200 rounded-t-lg md:rounded-t-none md:rounded-ss-lg md:border-e">
            <blockquote class="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8">
              <p class="my-4">
              I loved working with WebFlixo they understood all of our needs and completed. We are super happy. totally recommend!
              </p>
            </blockquote>
            <figcaption class="flex items-start justify-start">
              <img
                class="rounded-full w-9 h-9"
                src={Michael}
                alt="logo"
              />
              <div class="space-y-0.5 font-medium  text-left rtl:text-right ms-3">
                <div>Michael Schmidt </div>
                <div class="text-sm text-gray-500">
                 CMO at Audiencely
                </div>
              </div>
            </figcaption>
          </figure>
        
          <figure class="flex flex-col items-start justify-start p-8 text-left bg-white border-b border-gray-200 rounded-t-lg md:rounded-t-none md:rounded-ss-lg md:border-e">
            <blockquote class="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8">
              <p class="my-4">
              Working with WebFlixo was a breath of fresh air.  They're not your typical, pushy marketing agency. They listened to my needs, explained things clearly, and delivered exactly what they promised. The communication throughout the project was fantastic, and they kept me updated every step of the way. Highly recommend!
              </p>
            </blockquote>
            <figcaption class="flex items-start justify-start">
              <img
                class="rounded-full w-9 h-9"
                src={BuseKanal}
                alt="logo"
              />
              <div class="space-y-0.5 font-medium text-left rtl:text-right ms-3">
                <div>BUSE KANA </div>
                <div class="text-sm text-gray-500">
                Head of Marketing at Shyftup
                </div>
              </div>
            </figcaption>
          </figure>
      </div>
      <div class="grid gap-4">
          <figure class="flex flex-col items-start justify-start p-8 text-left bg-white border-b border-gray-200 rounded-t-lg md:rounded-t-none md:rounded-ss-lg md:border-e">
            <blockquote class="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8">
              <p class="my-4">
              Partnering with WebFlixo was one of the best decisions I've made for my business.  They've been able to seamlessly integrate their services into my existing workflow, and the results have been phenomenal.
              </p>
            </blockquote>
            <figcaption class="flex items-start justify-start">
              <img
                class="rounded-full w-9 h-9"
                src={philDuggan}
                alt="logo"
              />
              <div class="space-y-0.5 font-medium text-left rtl:text-right ms-3">
                <div>phil duggan</div>
                <div class="text-sm text-gray-500">
                Director of Marketing at B7
                </div>
              </div>
            </figcaption>
          </figure>
        
          <figure class="flex flex-col items-start justify-start p-8 text-left bg-white border-b border-gray-200 rounded-t-lg md:rounded-t-none md:rounded-ss-lg md:border-e">
            <blockquote class="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8">
              <p class="my-4">
              Investing in WebFlixo's services has been one of the smartest business decisions I've ever made.  Their data-driven approach ensures that every marketing dollar is spent wisely
              </p>
            </blockquote>
            <figcaption class="flex items-start justify-start">
              <img
                class="rounded-full w-9 h-9"
                src={mdImranHossain}
                alt="logo"
              />
              <div class="space-y-0.5 font-medium  text-left rtl:text-right ms-3">
                <div>Imran Hossain </div>
                <div class="text-sm text-gray-500">
                Executive at Debuggers Studio
                </div>
              </div>
            </figcaption>
          </figure>
      </div>
    </div>
  );
};

export default ReviewGallery;
