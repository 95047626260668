import React from 'react';
import AboutUs from '../components/AboutUs';
import Hero from '../components/Hero';
import HowWeWorks from '../components/HowWeWorks';
import Review from '../components/Review';
import Services from '../components/Services';
import Team from '../components/Team';
import TrustedBy from '../components/TrustedBy';

const Home = () => {
    return (
        <div>
            <Hero/>
            <TrustedBy/>
            <HowWeWorks/>
            <Services/>
            <Review/>
            <Team></Team>
            <AboutUs/>
        </div>
    );
};

export default Home;