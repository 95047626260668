import React from 'react';
import AboutUs from '../components/AboutUs';

const AboutUsPage = () => {
    return (
        <div className='my-12'>
            <AboutUs/>
        </div>
    );
};

export default AboutUsPage;