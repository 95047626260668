import React from 'react';
import YouCanBookMeWidget from '../components/YouCanBookMeWidget';

const BookConsultation = () => {
    return (
        <div className='my-28 lg:my-20'>
            <div className="text-center mt-4 md:mt-0">
          <h2
            className="text-2xl md:text-4xl tracking-tight font-extrabold text-gray-900"
          >Empower Your Agency <br/>Schedule Your White-Label Consultation
          </h2>
        </div>
            <YouCanBookMeWidget/>
        </div>
    );
};

export default BookConsultation;