import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images//webflixo.svg";

const Navbar = () => {
  return (
    <nav className="container mx-auto z-20 top-0 left-0 bg-white bg-opacity-95 fixed border-gray-200">
      <div className="max-w-screen-xl mx-auto flex flex-wrap justify-between items-center p-4">
        <Link to="/" className="w-3/12 lg:w-2/12 flex items-center">
          <img className="w-full lg:w-2/3" src={logo} alt="WebFlixo" />
        </Link>
        <div className="flex lg:order-2 space-x-4 items-center justify-between">
          <Link to="tel:+8801951815814">
            <button
              type="button"
              className="text-purple-900 hover:bg-gray-50 hover:text-purple-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center border border-gray-200 mr-3 md:mr-0"
            >
              <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/></svg>
            </button>
          </Link>

          <Link to="mailto:support@webflixo.com" target="_blank">
            <button
              type="button"
              className="text-white bg-black hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center mr-3 md:mr-0"
            >
              <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
  <path fill="white" d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/>
</svg>

            </button>
          </Link>
          <button
            data-collapse-toggle="navbar-cta"
            type="button"
            className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
            aria-controls="navbar-cta"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <div
          className="items-center justify-between hidden w-full lg:flex lg:w-auto lg:order-1"
          id="navbar-cta"
        >
          <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 md:flex-row md:space-x-8 md:mt-0 md:border-0 text-md uppercase">
            <li>
              <Link
                to="/services"
                className="block py-2 pl-3 pr-4 text-white bg-purple-900 rounded md:bg-transparent md:text-purple-900 md:p-0"
                aria-current="page"
              >
                Our White-Label Services
              </Link>
            </li>
            <li>
              <Link
                to="/review"
                className="block py-2 pl-3 pr-4 text-gray-900 rounded hover-bg-gray-100 md:hover-bg-transparent md:hover-text-purple-900 md:p-0"
              >
                Client Love
              </Link>
            </li>
            {/* <li>
              <Link
                to="/team"
                className="block py-2 pl-3 pr-4 text-gray-900 rounded hover-bg-gray-100 md:hover-bg-transparent md:hover-text-purple-900 md:p-0"
              >
                Team
              </Link>
            </li> */}
            <li>
              <Link
                to="/about"
                className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-purple-900 md:p-0"
              >
                About Us
              </Link>
            </li>
            {/* <li>
                <Link
                  to="/consultation"
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded hover-bg-gray-100 md:hover-bg-transparent md:hover-text-purple-900 md:p-0"
                >
                  Schedule a Meeting Now
                </Link>
              </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
