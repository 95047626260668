import React from 'react';
import hustlebugLogo from "../assets/images//Trusted By/hustlebug.png";
import kanehbosmLogo from "../assets/images//Trusted By/kanehbosm.png";
import shyftupLogo from "../assets/images//Trusted By/shyftup.png";
import soliexLogo from "../assets/images//Trusted By/soliex.png";
import startmystoreLogo from "../assets/images//Trusted By/startmystore.png";
import xenaLogo from "../assets/images//Trusted By/xena.png";


const TrustedBy = () => {
    return (
        <section className="container mx-auto p-8">
      <div className="px-4 mx-auto max-w-screen-xl text-center lg:px-12">
        <div className="px-4 mx-auto text-center md:max-w-screen-md lg:max-w-screen-lg lg:px-36">
          <p className="text-gray-500 text-l tracking-widest font-medium inline-flex">
          SOME OF OUR WHITE LABEL CLIENTS
          </p>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-6 gap-4 p-5 lg:p-12">
          <div>
            <img className="h-auto max-w-full rounded-lg" src={soliexLogo} alt="Google logo" />
          </div>
          <div>
            <img className="h-auto max-w-full rounded-lg" src={startmystoreLogo} alt="Microsoft Logo" />
          </div>
          <div>
            <img className="h-auto max-w-full rounded-lg" src={hustlebugLogo} alt="Trustpilot Logo" />
          </div>
          <div>
            <img className="h-auto max-w-full rounded-lg" src={kanehbosmLogo} alt="Stripe Logo" />
          </div>
          <div>
            <img className="h-auto max-w-full rounded-lg" src={shyftupLogo} alt="Upwork logo" />
          </div>
          <div>
            <img className="h-auto max-w-full rounded-lg" src={xenaLogo} alt="Fiverr Logo" />
          </div>
        </div>
      </div>
    </section>
    );
};

export default TrustedBy;