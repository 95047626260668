import React from 'react';
import Team from '../components/Team';

const TeamPage = () => {
    return (
        <div className='my-12'>
            <Team></Team>
        </div>
    );
};

export default TeamPage;